// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import CreateTransferPage from './pages/CreateTransferPage';
import TransferStatusPage from './pages/TransferStatusPage';
import TransferHistoryPage from './pages/TransferHistoryPage';
import Dashboard from './pages/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if token exists in localStorage on component mount
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    let inactivityTimeout;

    const resetTimer = () => {
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        // Log out user after 45 minutes of inactivity
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsAuthenticated(false);
        window.location.href = '/';
      }, 45 * 60 * 1000); // 45 minutes
    };

    // Events considered as user activity
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.onclick = resetTimer;
    window.onscroll = resetTimer;
    window.onkeypress = resetTimer;

    return () => {
      clearTimeout(inactivityTimeout);
      window.onload = null;
      window.onmousemove = null;
      window.onmousedown = null;
      window.onclick = null;
      window.onscroll = null;
      window.onkeypress = null;
    };
  }, []);

  return (
    <Router>
      {isAuthenticated && <Navbar />}
      <Routes>
        <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-transfer"
          element={
            <ProtectedRoute>
              <CreateTransferPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transfer-status"
          element={
            <ProtectedRoute>
              <TransferStatusPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transfer-history"
          element={
            <ProtectedRoute>
              <TransferHistoryPage />
            </ProtectedRoute>
          }
        />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
