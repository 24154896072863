// src/pages/TransferHistoryPage.js

import React, { useEffect, useState } from 'react';
import api from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function TransferHistoryPage() {
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [fecList, setFecList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingFec, setLoadingFec] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [selectedTransfers, setSelectedTransfers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransferDetails, setSelectedTransferDetails] = useState(null);
  const [updatingStatuses, setUpdatingStatuses] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // جلب قائمة العملات (FEC)
    const fetchFecList = async () => {
      try {
        const response = await api.get('/get-fec-list');
        if (response.data.fecList && response.data.fecList.length > 0) {
          setFecList(response.data.fecList);
        } else {
          toast.error('قائمة العملات فارغة.');
        }
      } catch (error) {
        toast.error('حدث خطأ أثناء جلب قائمة العملات.');
        console.error('Fetch FEC List Error:', error);
      } finally {
        setLoadingFec(false);
      }
    };

    // جلب الحوالات
    const fetchTransfers = async () => {
      try {
        const response = await api.get('/transfers');
        // ترتيب الحوالات حسب تاريخ الإنشاء (الأحدث أولًا)
        const sortedTransfers = response.data.transfers.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setTransfers(sortedTransfers);
        setFilteredTransfers(sortedTransfers);
        setLoading(false);

        // تعريف حالات الحوالة الممكنة
        const statusMap = {
          10: { statusName: 'جديد', statusDescription: 'تم إرسال الطلب وحالته الأولى جديدة.' },
          20: { statusName: 'مكتمل', statusDescription: 'تم إرسال الطلب بنجاح إلى البنك ومعالجته.' },
          30: { statusName: 'قيد الانتظار', statusDescription: 'الطلب معلق بسبب إجراءات مكافحة غسل الأموال، أو بانتظار الموافقة، أو بسبب رصيد غير كافٍ.' },
          40: { statusName: 'مرفوض', statusDescription: 'تم رفض الطلب من قبل البنك أو الجهات المختصة.' },
          50: { statusName: 'مرتجع', statusDescription: 'تم إعادة المبلغ من البنك.' },
          60: { statusName: 'ملغاة', statusDescription: 'تم إلغاء الطلب وهو في حالة جديدة.' },
        };

        // إعداد خيارات الفلترة بناءً على حالات الحوالة
        const uniqueStatusCodes = [...new Set(response.data.transfers.map(t => t.status.statusCode))];
        const uniqueStatusOptions = uniqueStatusCodes.map(code => ({
          statusCode: code,
          statusName: statusMap[code]?.statusName || `حالة ${code}`,
          statusDescription: statusMap[code]?.statusDescription || '',
        }));
        setStatusOptions(uniqueStatusOptions);
      } catch (error) {
        toast.error('حدث خطأ أثناء جلب بيانات الحوالات.');
        console.error('Fetch Transfers Error:', error);
        setLoading(false);
      }
    };

    fetchFecList();
    fetchTransfers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // تحديث الفلترة عند تغيير أي من معايير البحث أو الفلترة
  useEffect(() => {
    let tempTransfers = [...transfers];

    // تطبيق البحث
    if (searchQuery.trim() !== '') {
      tempTransfers = tempTransfers.filter(transfer =>
        transfer.senderInfo.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transfer.receiverInfo.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transfer.senderExtFirmRefId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // تطبيق فلترة الحالة
    if (filterStatus !== '') {
      tempTransfers = tempTransfers.filter(transfer => transfer.status.statusCode === parseInt(filterStatus));
    }

    // تطبيق فلترة العملة
    if (filterCurrency !== '') {
      tempTransfers = tempTransfers.filter(transfer => transfer.fecId === parseInt(filterCurrency));
    }

    // تطبيق فلترة تاريخ البدء
    if (filterStartDate) {
      tempTransfers = tempTransfers.filter(transfer => new Date(transfer.createdAt) >= filterStartDate);
    }

    // تطبيق فلترة تاريخ الانتهاء
    if (filterEndDate) {
      tempTransfers = tempTransfers.filter(transfer => new Date(transfer.createdAt) <= filterEndDate);
    }

    setFilteredTransfers(tempTransfers);
    // إعادة تعيين التحديد عند تغيير الفلترة
    setSelectedTransfers([]);
    setSelectAll(false);
  }, [searchQuery, filterStatus, filterCurrency, filterStartDate, filterEndDate, transfers]);

  // دالة لمعالجة تحديد حوالة
  const handleSelectTransfer = (event, transferId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTransfers(prev => [...prev, transferId]);
    } else {
      setSelectedTransfers(prev => prev.filter(id => id !== transferId));
    }
  };

  // دالة لمعالجة تحديد الكل
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allTransferIds = filteredTransfers.map(transfer => transfer._id);
      setSelectedTransfers(allTransferIds);
    } else {
      setSelectedTransfers([]);
    }
  };

  // دالة لتحديث حالة الحوالات المحددة
  const handleUpdateStatus = async () => {
    if (selectedTransfers.length === 0) {
      toast.warning('يرجى اختيار حوالة واحدة على الأقل لتحديث حالتها.');
      return;
    }

    try {
      setUpdatingStatuses(true);

      for (const transferId of selectedTransfers) {
        const transfer = transfers.find(t => t._id === transferId);
        if (!transfer) continue;

        // إرسال طلب لاستعلام حالة الحوالة من BRQ LINK
        const statusResponse = await api.post('/transfer-status-by-sender-ref', {
          senderExtFirmRefId: transfer.senderExtFirmRefId,
        });

        const updatedStatus = statusResponse.data.status;

        // إرسال طلب تحديث الحالة إلى الخادم الخلفي
        const updateResponse = await api.put('/update-transfer-status', {
          senderExtFirmRefId: transfer.senderExtFirmRefId,
          newStatusCode: updatedStatus.statusCode,
        });

        // تحديث الحالة في الحالة المحلية
        setTransfers(prevTransfers => {
          return prevTransfers.map(t => {
            if (t._id === transferId) {
              return {
                ...t,
                status: {
                  statusCode: updatedStatus.statusCode,
                  statusName: updatedStatus.statusName,
                  statusDescription: updatedStatus.statusDescription,
                },
              };
            }
            return t;
          });
        });
      }

      toast.success('تم تحديث حالة الحوالات المحددة بنجاح.');
      // إعادة تعيين التحديد
      setSelectedTransfers([]);
      setSelectAll(false);
    } catch (error) {
      toast.error('فشل في تحديث حالة الحوالات.');
      console.error('Update Status Error:', error);
    } finally {
      setUpdatingStatuses(false);
    }
  };

  // دالة لمعالجة النقر على صف الحوالة لعرض التفاصيل
  const handleRowClick = (transfer) => {
    setSelectedTransferDetails(transfer);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTransferDetails(null);
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '50px' }}>
      <Box
        sx={{
          padding: '30px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" gutterBottom>
          تاريخ الحوالات
        </Typography>

        {/* قسم البحث والفلترة */}
        <Box sx={{ marginBottom: '20px' }}>
          <Grid container spacing={2}>
            {/* حقل البحث */}
            <Grid item xs={12} sm={4}>
              <TextField
                label="بحث"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="بحث باسم المرسل، المستلم، أو رقم المرجع"
              />
            </Grid>

            {/* فلترة الحالة */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>حالة الحوالة</InputLabel>
                <Select
                  label="حالة الحوالة"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <MenuItem value="">
                    <em>جميع الحالات</em>
                  </MenuItem>
                  {statusOptions.map((status) => (
                    <MenuItem key={status.statusCode} value={status.statusCode}>
                      {status.statusName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* فلترة العملة */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>العملة</InputLabel>
                <Select
                  label="العملة"
                  value={filterCurrency}
                  onChange={(e) => setFilterCurrency(e.target.value)}
                >
                  <MenuItem value="">
                    <em>جميع العملات</em>
                  </MenuItem>
                  {fecList.map((fec) => (
                    <MenuItem key={fec.fecId} value={fec.fecId}>
                      {fec.fecFullName} ({fec.fecCode})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* فلترة تاريخ البدء */}
            <Grid item xs={12} sm={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="تاريخ البدء"
                  value={filterStartDate}
                  onChange={(newValue) => setFilterStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </LocalizationProvider>
            </Grid>

            {/* فلترة تاريخ الانتهاء */}
            <Grid item xs={12} sm={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="تاريخ الانتهاء"
                  value={filterEndDate}
                  onChange={(newValue) => setFilterEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                />
              </LocalizationProvider>
            </Grid>

            {/* زر تحديث الحالة */}
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateStatus}
                disabled={selectedTransfers.length === 0 || updatingStatuses}
              >
                {updatingStatuses ? 'جارٍ تحديث الحالات...' : 'تحديث حالة الحوالات المحددة'}
              </Button>
            </Grid>
          </Grid>
        </Box>

        {loading || loadingFec ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {filteredTransfers.length === 0 ? (
              <Typography variant="h6" color="textSecondary" style={{ marginTop: '20px' }}>
                لا توجد حوالات مطابقة للبحث أو الفلترة.
              </Typography>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                          inputProps={{ 'aria-label': 'select all transfers' }}
                        />
                      </TableCell>
                      <TableCell>تاريخ إنشاء الطلب</TableCell>
                      <TableCell>نوع الحوالة</TableCell>
                      <TableCell>اسم المرسل</TableCell>
                      <TableCell>القيمة</TableCell>
                      <TableCell>العملة</TableCell>
                      <TableCell>اسم المستلم</TableCell>
                      <TableCell>حالة الحوالة</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTransfers.map((transfer) => (
                      <TableRow key={transfer._id} hover>
                        <TableCell>
                          <Checkbox
                            checked={selectedTransfers.includes(transfer._id)}
                            onChange={(e) => handleSelectTransfer(e, transfer._id)}
                            inputProps={{ 'aria-label': `select transfer ${transfer._id}` }}
                          />
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {new Date(transfer.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {getTransferTypeName(transfer.transferTypeId)}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {transfer.senderInfo.fullName}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {transfer.amount}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {fecList.find(fec => fec.fecId === transfer.fecId)?.fecFullName || 'غير محددة'}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {transfer.receiverInfo.fullName}
                        </TableCell>
                        <TableCell onClick={() => handleRowClick(transfer)} style={{ cursor: 'pointer' }}>
                          {transfer.status.statusName} ({transfer.status.statusCode})
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>
      <ToastContainer />

      {/* نافذة منبثقة لعرض تفاصيل الحوالة */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedTransferDetails && (
              <>
                <Typography variant="h5" gutterBottom>
                  تفاصيل الحوالة
                </Typography>
                <Typography variant="body1"><strong>رقم المرجع المحلي:</strong> {selectedTransferDetails.senderExtFirmRefId}</Typography>
                <Typography variant="body1"><strong>رقم المرجع الخاص ببرق لينك:</strong> {selectedTransferDetails.transferOrderRefId}</Typography>
                <Typography variant="body1"><strong>تاريخ الحوالة:</strong> {new Date(selectedTransferDetails.transferDate).toLocaleDateString()}</Typography>
                <Typography variant="body1"><strong>تاريخ الإنشاء:</strong> {new Date(selectedTransferDetails.createdAt).toLocaleDateString()}</Typography>
                <Typography variant="body1"><strong>اسم المرسل:</strong> {selectedTransferDetails.senderInfo.fullName}</Typography>
                <Typography variant="body1"><strong>مكان الميلاد للمرسل:</strong> {selectedTransferDetails.senderInfo.birthPlace}</Typography>
                <Typography variant="body1"><strong>تاريخ ميلاد المرسل:</strong> {new Date(selectedTransferDetails.senderInfo.birthDay).toLocaleDateString()}</Typography>
                <Typography variant="body1"><strong>اسم المستلم:</strong> {selectedTransferDetails.receiverInfo.fullName}</Typography>
                <Typography variant="body1"><strong>مكان الميلاد للمستلم:</strong> {selectedTransferDetails.receiverInfo.birthPlace}</Typography>
                <Typography variant="body1"><strong>تاريخ ميلاد المستلم:</strong> {new Date(selectedTransferDetails.receiverInfo.birthDay).toLocaleDateString()}</Typography>
                <Typography variant="body1"><strong>القيمة:</strong> {selectedTransferDetails.amount}</Typography>
                <Typography variant="body1"><strong>العملة:</strong> {fecList.find(fec => fec.fecId === selectedTransferDetails.fecId)?.fecFullName || 'غير محددة'}</Typography>
                <Typography variant="body1"><strong>نوع الحوالة:</strong> {getTransferTypeName(selectedTransferDetails.transferTypeId)}</Typography>
                <Typography variant="body1"><strong>حالة الحوالة:</strong> {selectedTransferDetails.status.statusName} ({selectedTransferDetails.status.statusCode})</Typography>
                {/* يمكنك إضافة المزيد من التفاصيل هنا */}
                <Box sx={{ mt: 2 }}>
                  <Button variant="contained" onClick={handleCloseModal}>
                    إغلاق
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );

  // دالة مساعدة للحصول على اسم نوع الحوالة
  function getTransferTypeName(transferTypeId) {
    const transferTypeMap = {
      1: 'نقدًا',
      2: 'إلى حساب',
      3: 'إلى بطاقة ائتمان',
      4: 'إلى IBAN',
    };
    return transferTypeMap[transferTypeId] || 'غير معروف';
  }
}

export default TransferHistoryPage;
