// src/pages/TransferStatusPage.js

import React, { useState } from 'react';
import api from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TransferStatusPage() {
  const [transferOrderRefId, setTransferOrderRefId] = useState('');
  const [senderExtFirmRefId, setSenderExtFirmRefId] = useState('');
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  const handleCheckStatus = async (e) => {
    e.preventDefault();
    try {
      const username = localStorage.getItem('username'); // استرجاع اسم المستخدم من localStorage
      if (!username) {
        toast.error('المستخدم غير مسجل. يرجى تسجيل الدخول مرة أخرى.');
        navigate('/');
        return;
      }

      // التحقق من تقديم أحد المعرفين
      if (!transferOrderRefId && !senderExtFirmRefId) {
        toast.error('يرجى إدخال رقم مرجع الحوالة أو معرف المرسل.');
        return;
      }

      let endpoint = '';
      let data = { username };

      if (transferOrderRefId) {
        endpoint = '/transfer-status';
        data.transferOrderRefId = transferOrderRefId;
      } else if (senderExtFirmRefId) {
        endpoint = '/transfer-status-by-sender-ref';
        data.senderExtFirmRefId = senderExtFirmRefId;
      }

      const response = await api.post(endpoint, data);

      toast.success('تم استعلام حالة الحوالة بنجاح');
      setStatus(response.data.status);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('حدث خطأ ما');
      }
      console.error('Transfer Status Error:', error);
    }
  };

  return (
    <div style={styles.container}>
      <h2>استعلام حالة الحوالة</h2>
      <form onSubmit={handleCheckStatus} style={styles.form}>
        <input
          type="text"
          placeholder="رقم مرجع الحوالة"
          value={transferOrderRefId}
          onChange={(e) => setTransferOrderRefId(e.target.value)}
          style={styles.input}
        />
        <span style={styles.orText}>أو</span>
        <input
          type="text"
          placeholder="معرف المرسل"
          value={senderExtFirmRefId}
          onChange={(e) => setSenderExtFirmRefId(e.target.value)}
          style={styles.input}
        />
        <button type="submit" style={styles.button}>استعلام</button>
      </form>
      <ToastContainer />

      {status && (
        <div style={styles.statusContainer}>
          <h3>حالة الحوالة:</h3>
          <p><strong>رمز الحالة:</strong> {status.statusCode}</p>
          <p><strong>اسم الحالة:</strong> {status.statusName}</p>
          <p><strong>وصف الحالة:</strong> {status.statusDescription}</p>
          {/* يمكنك إضافة المزيد من المعلومات حسب الاستجابة */}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '30px',
    textAlign: 'center',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '50px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '80%',
  },
  orText: {
    fontSize: '16px',
    color: '#555',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#2196F3',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  statusContainer: {
    marginTop: '30px',
    textAlign: 'left',
    padding: '20px',
    border: '1px solid #4CAF50',
    borderRadius: '8px',
    backgroundColor: '#f9fff9',
  },
};

export default TransferStatusPage;
