// src/utils/pdfGenerator.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Generates a professionally formatted PDF receipt for the transfer in English.
 * @param {string} transferOrderRefId - The reference ID of the transfer.
 * @param {object} data - The transfer data.
 * @param {string} qrLocalDataURL - Data URL for the local QR code.
 * @param {string} qrBRQDataURL - Data URL for the BRQ QR code.
 */
export const generatePDF = (transferOrderRefId, data, qrLocalDataURL, qrBRQDataURL) => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 15;

  // إعداد الهوامش
  const usableWidth = pageWidth - 2 * margin;

  // عنوان الـ PDF
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(22);
  doc.text('Transfer Receipt', pageWidth / 2, 20, { align: 'center' });

  // خط فاصل تحت العنوان
  doc.setLineWidth(0.5);
  doc.line(margin, 25, pageWidth - margin, 25);

  // بيانات الحوالة
  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);
  let currentY = 30;

  // قسم بيانات الحوالة
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(14);
  doc.text('Transfer Details', margin, currentY);
  currentY += 7;

  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);

  // بناء بيانات الحوالة بناءً على نوع الحوالة
  const transferDetails = [
    ['Transfer Type', data.transferType.transferTypeId === 1 ? 'TO ACCOUNT' :
                       data.transferType.transferTypeId === 2 ? 'TO CREDIT CARD' :
                       data.transferType.transferTypeId === 3 ? 'TO IBAN' : 'TO NAME'],
    ['Sender Name', String(data.senderInfo.fullName)],
    ['Receiver Name', String(data.receiverInfo.fullName)],
    ['Transfer Date', String(new Date(data.transferDate).toLocaleDateString())],
    ['Local Reference', String(data.senderExtFirmRefId)],
    ['BRQ Reference', String(transferOrderRefId || 'N/A')],
    ['Amount', `${data.amount} TL`],
    ['Comment', String(data.comment || 'N/A')],
  ];

  // إضافة رقم الحساب/البطاقة/الـ IBAN بناءً على نوع الحوالة
  if (data.transferType.transferTypeId !== 4) {
    transferDetails.push(['Receiver Account', String(data.receiverAccount.accountNo)]);
  }

  doc.autoTable({
    startY: currentY,
    head: [['Field', 'Details']],
    body: transferDetails,
    theme: 'striped',
    headStyles: { fillColor: [63, 81, 181], textColor: 255 },
    styles: { fontSize: 12 },
    margin: { left: margin, right: margin },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // قسم QR Codes
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(14);
  doc.text('QR Codes', margin, currentY);
  currentY += 7;

  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);

  const qrSize = 30; // حجم الـ QR code

  // QR Code المحلي
  doc.text('Local Reference QR:', margin, currentY);
  doc.addImage(qrLocalDataURL, 'PNG', margin, currentY + 2, qrSize, qrSize);

  // QR Code الخاص ببرق لينك
  doc.text('BRQ Reference QR:', margin + 100, currentY);
  doc.addImage(qrBRQDataURL, 'PNG', margin + 100, currentY + 2, qrSize, qrSize);

  currentY += qrSize + 15;

  // قسم المراجع
  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(14);
  doc.text('References', margin, currentY);
  currentY += 7;

  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);

  const references = [
    ['Local Ref', String(data.senderExtFirmRefId)],
    ['BRQ Ref', String(transferOrderRefId || 'N/A')],
  ];

  doc.autoTable({
    startY: currentY,
    head: [['Reference Type', 'Reference ID']],
    body: references,
    theme: 'striped',
    headStyles: { fillColor: [63, 81, 181], textColor: 255 },
    styles: { fontSize: 12 },
    margin: { left: margin, right: margin },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // خط فاصل قبل التذييل
  doc.setLineWidth(0.3);
  doc.line(margin, currentY, pageWidth - margin, currentY);
  currentY += 7;

  // تذييل الـ PDF
  doc.setFont('Helvetica', 'italic');
  doc.setFontSize(10);
  doc.text('Thank you for using our service.', pageWidth / 2, currentY, { align: 'center' });

  // حفظ الـ PDF
  doc.save(`Transfer_${transferOrderRefId}.pdf`);
};
