// src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { getTransferStats, getTransfersOverTime } from '../services/brqlinkService'; // تأكد من وجود دالة getTransfersOverTime
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import '../styles/Dashboard.css';
import { Chart, registerables } from 'chart.js';
import { toast } from 'react-toastify'; // استيراد toast

// تسجيل مكونات Chart.js
Chart.register(...registerables);

function Dashboard() {
  const [stats, setStats] = useState({
    totalTransfers: 0,
    approvedTransfers: 0,
    canceledTransfers: 0,
    pendingTransfers: 0,
  });
  const [chartData, setChartData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await getTransferStats();
        setStats(data);

        // تنفيذ دالة لجلب بيانات الحوالات على مدار الوقت
        const transfersOverTime = await getTransfersOverTime();
        setChartData({
          labels: transfersOverTime.dates,
          datasets: [
            {
              label: 'عدد الحوالات',
              data: transfersOverTime.counts,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              fill: true,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching transfer stats:', error);
        toast.error('حدث خطأ في جلب إحصائيات الحوالات.');
      }
    };

    fetchStats();
  }, []);

  return (
    <Container maxWidth="lg" className="dashboard-container">
      <Typography variant="h4" gutterBottom align="center">
        لوحة التحكم
      </Typography>

      <Grid container spacing={3}>
        {/* إجمالي الحوالات */}
        <Grid item xs={12} sm={6} md={3}>
          <Card className="stat-card total-transfers">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                إجمالي الحوالات
              </Typography>
              <Typography variant="h4">{stats.totalTransfers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* الحوالات الموافق عليها */}
        <Grid item xs={12} sm={6} md={3}>
          <Card className="stat-card approved-transfers">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                الحوالات الموافق عليها
              </Typography>
              <Typography variant="h4">{stats.approvedTransfers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* الحوالات الملغاة */}
        <Grid item xs={12} sm={6} md={3}>
          <Card className="stat-card canceled-transfers">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                الحوالات الملغاة
              </Typography>
              <Typography variant="h4">{stats.canceledTransfers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* الحوالات قيد التجهيز */}
        <Grid item xs={12} sm={6} md={3}>
          <Card className="stat-card pending-transfers">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                الحوالات قيد التجهيز
              </Typography>
              <Typography variant="h4">{stats.pendingTransfers}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* مخطط بياني للحوالات */}
      {chartData && (
        <Box className="chart-container">
          <Typography variant="h6" gutterBottom align="center">
            إحصائيات الحوالات على مدار الوقت
          </Typography>
          <Line data={chartData} />
        </Box>
      )}

      {/* أزرار الوصول */}
      <Grid container spacing={3} style={{ marginTop: '30px' }} justifyContent="center">
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate('/create-transfer')}
          >
            إنشاء حوالة
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => navigate('/transfer-history')}
          >
            تقرير الحوالات
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
