// src/services/brqlinkService.js

import api from './apiService';

// دالة للحصول على إحصائيات الحوالات
export const getTransferStats = async () => {
  try {
    const response = await api.get('/transfer-stats');
    return response.data;
  } catch (error) {
    console.error('Error fetching transfer stats:', error);
    throw error;
  }
};

// دالة للحصول على الحوالات على مدار الوقت
export const getTransfersOverTime = async () => {
  try {
    const response = await api.get('/transfers-over-time');
    return response.data;
  } catch (error) {
    console.error('Error fetching transfers over time:', error);
    throw error;
  }
};

// يمكنك إضافة دوال أخرى هنا حسب الحاجة
