// src/pages/CreateTransferPage.js

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/apiService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CreateTransferPage.css';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  InputLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { QRCodeCanvas } from 'qrcode.react';
import { generatePDF } from '../utils/pdfGenerator';
import PersonInfo from '../components/PersonInfo';
import jsPDF from 'jspdf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Define the form validation schema using yup
const schema = yup.object().shape({
  transferType: yup.object().shape({
    transferTypeId: yup
      .number()
      .typeError('Transfer type must be a number')
      .required('Transfer type is required'),
  }),
  amount: yup
    .number()
    .typeError('Amount must be a number')
    .required('Amount is required')
    .positive('Amount must be positive'),
  comment: yup.string(),
  fec: yup.object().shape({
    fecId: yup
      .number()
      .integer()
      .when(['transferType.transferTypeId'], (transferTypeId, schema) => {
        if (transferTypeId === 1) {
          return schema.required('Currency is required');
        } else {
          return schema
            .required('Currency is required')
            .oneOf([1], 'The only available currency is Turkish Lira');
        }
      }),
  }),
  receiverAccount: yup.object().shape({
    accountNo: yup.string().when(['transferType.transferTypeId'], (transferTypeId, schema) => {
      if ([2, 3, 4].includes(transferTypeId)) {
        return schema
          .required('Account/Card/IBAN number is required')
          .matches(/^[A-Za-z0-9]+$/, 'Only English letters and numbers are allowed');
      } else {
        return schema.notRequired();
      }
    }),
  }),
  receiverInfo: yup.object().shape({
    fullName: yup
      .string()
      .required("Receiver's full name is required")
      .matches(/^[A-Za-z ]+$/, 'Only English letters are allowed'),
    birthDay: yup
      .date()
      .required("Receiver's date of birth is required")
      .typeError('Invalid date of birth'),
    birthPlace: yup
      .string()
      .required("Receiver's place of birth is required")
      .matches(/^[A-Za-z ]+$/, 'Only English letters are allowed'),
  }),
  senderInfo: yup.object().shape({
    fullName: yup
      .string()
      .required("Sender's full name is required")
      .matches(/^[A-Za-z ]+$/, 'Only English letters are allowed'),
    birthDay: yup
      .date()
      .required("Sender's date of birth is required")
      .typeError('Invalid date of birth'),
    birthPlace: yup
      .string()
      .required("Sender's place of birth is required")
      .matches(/^[A-Za-z ]+$/, 'Only English letters are allowed'),
  }),
  transferDate: yup
    .date()
    .required('Transfer date is required')
    .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Cannot select a past date')
    .typeError('Invalid transfer date'),
  transferReason: yup.object().shape({
    reasonTypeId: yup
      .number()
      .typeError('Transfer reason must be a number')
      .required('Transfer reason is required'),
    value: yup
      .string()
      .required('Transfer reason description is required')
      .matches(/^[A-Za-z0-9 ]+$/, 'Only English letters and numbers are allowed'),
  }),
});

function CreateTransferPage() {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      transferType: { transferTypeId: '' },
      amount: '',
      comment: '',
      receiverAccount: { accountNo: '' },
      receiverInfo: { fullName: '', birthDay: '', birthPlace: '' },
      senderInfo: { fullName: '', birthDay: '', birthPlace: '' },
      transferDate: new Date().toISOString().split('T')[0],
      transferReason: { reasonTypeId: '', value: '' },
      fec: { fecId: '' },
    },
  });

  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [transferOrderRefId, setTransferOrderRefId] = useState('');
  const qrLocalRef = useRef(null);
  const qrBRQRef = useRef(null);
  const [fecList, setFecList] = useState([]);
  const [loadingFec, setLoadingFec] = useState(true);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // Generate a unique local reference ID for each transfer
  const generateLocalRefId = () => `HQ${Date.now()}`;

  const selectedTransferType = watch('transferType.transferTypeId');

  useEffect(() => {
    const fetchFecList = async () => {
      try {
        const response = await api.get('/get-fec-list');
        if (response.data.fecList && response.data.fecList.length > 0) {
          setFecList(response.data.fecList);
        } else {
          toast.error('Currency list is empty');
        }
      } catch (error) {
        console.error('Error fetching FEC list:', error);
        toast.error('An error occurred while fetching the currency list');
      } finally {
        setLoadingFec(false);
      }
    };

    fetchFecList();
  }, []);

  useEffect(() => {
    if (selectedTransferType !== 1) {
      setValue('fec.fecId', 1);
    } else {
      setValue('fec.fecId', '');
    }
  }, [selectedTransferType, setValue]);

  const onSubmit = (data) => {
    console.log('Form submitted', data);
    const localRefId = generateLocalRefId();

    const transformedData = {
      ...data,
      senderExtFirmRefId: localRefId,
    };

    setFormData(transformedData);
    setOpen(true);
  };

  const handleDownloadPreliminaryPDF = () => {
    const doc = new jsPDF();
    let yPosition = 10;

    doc.setFontSize(16);
    doc.text('Preliminary Transfer Data', 105, yPosition, null, null, 'center');
    yPosition += 10;

    const dataRows = getDataRows();

    doc.setFontSize(12);
    dataRows.forEach((row) => {
      doc.text(`${row.label}: ${row.value}`, 10, yPosition);
      yPosition += 7;
    });

    doc.save('Preliminary_Transfer_Data.pdf');
  };

  const handleConfirm = async () => {
    setLoadingSubmit(true);
    setOpen(false);
    try {
      const payload = {
        ...formData,
      };

      const response = await api.post('/create-transfer', payload);

      setTransferOrderRefId(response.data.transferOrderRefId);

      const qrLocalDataURL = qrLocalRef.current.toDataURL();
      const qrBRQDataURL = qrBRQRef.current.toDataURL();

      generatePDF(
        response.data.transferOrderRefId,
        {
          ...formData,
        },
        qrLocalDataURL,
        qrBRQDataURL
      );

      setSuccessDialogOpen(true);
      reset({
        transferType: { transferTypeId: '' },
        amount: '',
        comment: '',
        receiverAccount: { accountNo: '' },
        receiverInfo: { fullName: '', birthDay: '', birthPlace: '' },
        senderInfo: { fullName: '', birthDay: '', birthPlace: '' },
        transferDate: new Date().toISOString().split('T')[0],
        transferReason: { reasonTypeId: '', value: '' },
        fec: { fecId: '' },
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An error occurred');
      }
      console.error('Create Transfer Error:', error);
    } finally {
      setLoadingSubmit(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    navigate('/dashboard');
  };

  // Function to get data rows for PDF generation
  const getDataRows = () => {
    const dataRows = [
      {
        label: 'Transfer Type',
        value:
          selectedTransferType === 1
            ? 'Transfer to Name'
            : selectedTransferType === 2
            ? 'Transfer to Account'
            : selectedTransferType === 3
            ? 'Transfer to Credit Card'
            : selectedTransferType === 4
            ? 'Transfer to IBAN'
            : 'Not specified',
      },
      { label: 'Sender Name', value: formData.senderInfo?.fullName },
      { label: 'Receiver Name', value: formData.receiverInfo?.fullName },
    ];

    if ([2, 3, 4].includes(Number(selectedTransferType))) {
      dataRows.push({
        label:
          selectedTransferType === 2
            ? "Receiver's Account Number"
            : selectedTransferType === 3
            ? 'Credit Card Number'
            : 'IBAN Number',
        value: formData.receiverAccount?.accountNo,
      });
    }

    if (selectedTransferType === 1) {
      dataRows.push({
        label: 'Currency',
        value:
          fecList.find((fec) => fec.fecId === formData.fec?.fecId)?.fecFullName || 'Not specified',
      });
    }

    dataRows.push(
      {
        label: 'Transfer Date',
        value: formData.transferDate
          ? new Date(formData.transferDate).toLocaleDateString()
          : 'Not specified',
      },
      { label: 'Local Reference Number', value: formData.senderExtFirmRefId },
      {
        label: 'Amount',
        value: `${formData.amount} ${
          selectedTransferType === 1
            ? fecList.find((fec) => fec.fecId === formData.fec?.fecId)?.fecCode || 'Not specified'
            : 'TL'
        }`,
      },
      { label: 'Transfer Reason', value: formData.transferReason?.value }
    );

    return dataRows;
  };

  return (
    <Container maxWidth="md" className="container">
      <Typography variant="h4" gutterBottom align="center">
        Create Transfer
      </Typography>
      <Paper elevation={3} style={{ padding: '30px' }}>
        {loadingFec ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            <Grid container spacing={3}>
              {/* Transfer Type */}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" className="select" required>
                  <InputLabel id="transfer-type-label">Transfer Type</InputLabel>
                  <Controller
                    name="transferType.transferTypeId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="transfer-type-label"
                        label="Transfer Type"
                        error={!!errors.transferType?.transferTypeId}
                      >
                        <MenuItem value="">
                          <em>Select Transfer Type</em>
                        </MenuItem>
                        <MenuItem value={1}>Transfer to Name</MenuItem>
                        <MenuItem value={2}>Transfer to Account</MenuItem>
                        <MenuItem value={3}>Transfer to Credit Card</MenuItem>
                        <MenuItem value={4}>Transfer to IBAN</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.transferType?.transferTypeId && (
                    <Typography variant="body2" color="error">
                      {errors.transferType.transferTypeId.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Amount and Currency */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Amount"
                        type="number"
                        variant="outlined"
                        error={!!errors.amount}
                        helperText={errors.amount ? errors.amount.message : ''}
                        required
                        className="input"
                        inputProps={{ min: '0', step: '0.01', pattern: '^[0-9]*\\.?[0-9]+$' }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {selectedTransferType === 1 && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" className="select" required>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Controller
                      name="fec.fecId"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="currency-label"
                          label="Currency"
                          error={!!errors.fec?.fecId}
                        >
                          <MenuItem value="">
                            <em>Select Currency</em>
                          </MenuItem>
                          {fecList.map((fec) => (
                            <MenuItem key={fec.fecId} value={fec.fecId}>
                              {fec.fecFullName} ({fec.fecCode})
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.fec?.fecId && (
                      <Typography variant="body2" color="error">
                        {errors.fec.fecId.message}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              )}

              {selectedTransferType !== 1 && (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      label="Currency"
                      variant="outlined"
                      value="Turkish Lira (TL)"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              {/* Transfer Comment */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Transfer Comment"
                        type="text"
                        variant="outlined"
                        error={!!errors.comment}
                        helperText={errors.comment ? errors.comment.message : ''}
                        className="input"
                        inputProps={{ pattern: '^[A-Za-z0-9 ]*$' }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Account/Card/IBAN Number */}
              {[2, 3, 4].includes(Number(selectedTransferType)) && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="receiverAccount.accountNo"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            selectedTransferType === 2
                              ? "Receiver's Account Number"
                              : selectedTransferType === 3
                              ? 'Credit Card Number'
                              : 'IBAN Number'
                          }
                          type="text"
                          variant="outlined"
                          error={!!errors.receiverAccount?.accountNo}
                          helperText={
                            errors.receiverAccount?.accountNo
                              ? errors.receiverAccount.accountNo.message
                              : ''
                          }
                          required
                          className="input"
                          inputProps={{ pattern: '^[A-Za-z0-9]+$' }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              )}

              {/* Receiver Information */}
              <Grid item xs={12}>
                <PersonInfo
                  control={control}
                  errors={errors.receiverInfo}
                  prefix="receiverInfo"
                  labelName="Receiver"
                />
              </Grid>

              {/* Sender Information */}
              <Grid item xs={12}>
                <PersonInfo
                  control={control}
                  errors={errors.senderInfo}
                  prefix="senderInfo"
                  labelName="Sender"
                />
              </Grid>

              {/* Transfer Date */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name="transferDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Transfer Date"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split('T')[0],
                        }}
                        error={!!errors.transferDate}
                        helperText={errors.transferDate ? errors.transferDate.message : ''}
                        required
                        className="input"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Transfer Reason */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" className="select" required>
                  <InputLabel id="transfer-reason-label">Transfer Reason</InputLabel>
                  <Controller
                    name="transferReason.reasonTypeId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="transfer-reason-label"
                        label="Transfer Reason"
                        error={!!errors.transferReason?.reasonTypeId}
                      >
                        <MenuItem value="">
                          <em>Select Transfer Reason</em>
                        </MenuItem>
                        <MenuItem value={1}>House Rent</MenuItem>
                        <MenuItem value={2}>Office Rent</MenuItem>
                        <MenuItem value={3}>Other Rents</MenuItem>
                        <MenuItem value={4}>Payments</MenuItem>
                        <MenuItem value={5}>Education</MenuItem>
                        <MenuItem value={6}>Credit Card Debts</MenuItem>
                        <MenuItem value={7}>Employee Payments</MenuItem>
                        <MenuItem value={9}>E-commerce Payments</MenuItem>
                        <MenuItem value={99}>Other Payments</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.transferReason?.reasonTypeId && (
                    <Typography variant="body2" color="error">
                      {errors.transferReason.reasonTypeId.message}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              {/* Transfer Reason Description */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="transferReason.value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Transfer Reason Description"
                        type="text"
                        variant="outlined"
                        error={!!errors.transferReason?.value}
                        helperText={
                          errors.transferReason?.value ? errors.transferReason.value.message : ''
                        }
                        required
                        className="input"
                        inputProps={{ pattern: '^[A-Za-z0-9 ]+$' }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="button"
                  disabled={loadingSubmit}
                >
                  {loadingSubmit ? 'Processing...' : 'Submit Transfer'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please verify the following information before submitting the transfer:
          </DialogContentText>
          {/* Confirmation Content */}
          <ConfirmationContent
            formData={formData}
            transferOrderRefId={transferOrderRefId}
            fecList={fecList}
            selectedTransferType={selectedTransferType}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownloadPreliminaryPDF} color="primary">
            Download Preliminary Data
          </Button>
          <Button onClick={handleCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* QR Codes (Hidden) */}
      <div style={{ display: 'none' }}>
        <QRCodeCanvas
          id="qr-local"
          value={`Local Ref: ${formData.senderExtFirmRefId}`}
          size={100}
          ref={qrLocalRef}
        />
        <QRCodeCanvas
          id="qr-brq"
          value={`BRQ Ref: ${transferOrderRefId}`}
          size={100}
          ref={qrBRQRef}
        />
      </div>

      {/* Success Dialog */}
      <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="primary" style={{ marginRight: '10px' }} />
            Operation Successful
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The transfer has been successfully created. Reference Number:{' '}
            <strong>{transferOrderRefId}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </Container>
  );
}

// Confirmation Content Component
const ConfirmationContent = ({ formData, fecList, selectedTransferType }) => {
  const dataRows = [
    {
      label: 'Transfer Type',
      value:
        selectedTransferType === 1
          ? 'Transfer to Name'
          : selectedTransferType === 2
          ? 'Transfer to Account'
          : selectedTransferType === 3
          ? 'Transfer to Credit Card'
          : selectedTransferType === 4
          ? 'Transfer to IBAN'
          : 'Not specified',
    },
    { label: 'Sender Name', value: formData.senderInfo?.fullName },
    { label: 'Receiver Name', value: formData.receiverInfo?.fullName },
  ];

  if ([2, 3, 4].includes(Number(selectedTransferType))) {
    dataRows.push({
      label:
        selectedTransferType === 2
          ? "Receiver's Account Number"
          : selectedTransferType === 3
          ? 'Credit Card Number'
          : 'IBAN Number',
      value: formData.receiverAccount?.accountNo,
    });
  }

  if (selectedTransferType === 1) {
    dataRows.push({
      label: 'Currency',
      value:
        fecList.find((fec) => fec.fecId === formData.fec?.fecId)?.fecFullName || 'Not specified',
    });
  }

  dataRows.push(
    {
      label: 'Transfer Date',
      value: formData.transferDate
        ? new Date(formData.transferDate).toLocaleDateString()
        : 'Not specified',
    },
    { label: 'Local Reference Number', value: formData.senderExtFirmRefId },
    {
      label: 'Amount',
      value: `${formData.amount} ${
        selectedTransferType === 1
          ? fecList.find((fec) => fec.fecId === formData.fec?.fecId)?.fecCode || 'Not specified'
          : 'TL'
      }`,
    },
    { label: 'Transfer Reason', value: formData.transferReason?.value }
  );

  return (
    <Box mt={2}>
      <TableContainer>
        <Table>
          <TableBody>
            {dataRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>
                  {row.label}
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Placeholder for customer signature */}
      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          Customer Signature: __________________________
        </Typography>
      </Box>
    </Box>
  );
};

export default CreateTransferPage;
