// src/pages/LoginPage.js

import React, { useState, useEffect } from 'react';
import api from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

function LoginPage({ setIsAuthenticated }) { // Receive setIsAuthenticated as prop
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const [lockTime, setLockTime] = useState(30); // Lockout duration in seconds
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isLocked && lockTime > 0) {
      timer = setInterval(() => {
        setLockTime((prev) => prev - 1);
      }, 1000);
    } else if (isLocked && lockTime === 0) {
      setIsLocked(false);
      setFailedAttempts(0);
      setLockTime(30);
      toast.info('You can try logging in again.');
    }
    return () => clearInterval(timer);
  }, [isLocked, lockTime]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (isLocked) return;

    setLoading(true);
    try {
      const response = await api.post('/login', {
        username,
        password,
      });

      // Store JWT token in localStorage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', username);

      toast.success('Login successful');
      setIsAuthenticated(true); // Update authentication state
      navigate('/dashboard'); // Redirect to dashboard
    } catch (error) {
      setFailedAttempts((prev) => prev + 1);
      if (failedAttempts + 1 >= 3) {
        setIsLocked(true);
        toast.error(
          'Too many failed attempts. Please wait 30 seconds before trying again.'
        );
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message);
        } else {
          toast.error('Something went wrong');
        }
      }
      console.error('Login Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5', // Light background color
      }}
    >
      <Box
        sx={{
          width: '100%',
          padding: '40px',
          borderRadius: '12px',
          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow
          backgroundColor: '#fff', // Box background color
          textAlign: 'center',
        }}
      >
        <LockIcon
          sx={{ fontSize: 60, color: '#1976d2', marginBottom: '20px' }}
        />
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Welcome back! Please enter your credentials to log in.
        </Typography>
        <form onSubmit={handleLogin} style={{ marginTop: '20px' }}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            disabled={isLocked || loading}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isLocked || loading}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
              },
            }}
          />
          {isLocked && (
            <Typography
              variant="body2"
              color="error"
              sx={{ marginTop: '10px' }}
            >
              Too many failed attempts. Please wait {lockTime} seconds.
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isLocked || loading}
            sx={{
              marginTop: '20px',
              padding: '12px',
              borderRadius: '8px',
              backgroundColor: '#1976d2', // Primary button color
              '&:hover': {
                backgroundColor: '#1565c0', // Hover color
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Login'
            )}
          </Button>
        </form>
      </Box>
      <ToastContainer />
    </Container>
  );
}

export default LoginPage;
