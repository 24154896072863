// src/components/PersonInfo.js

import React from 'react';
import { Grid, Typography, FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

function PersonInfo({ control, errors, prefix, labelName }) {
  return (
    <Grid container spacing={2}>
      {/* Section Header */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          {labelName === 'receiverInfo' ? 'Receiver Information' : 'Sender Information'}
        </Typography>
      </Grid>

      {/* Full Name */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined">
          <Controller
            name={`${prefix}.fullName`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Full Name"
                type="text"
                variant="outlined"
                error={!!errors?.[prefix]?.fullName}
                helperText={errors?.[prefix]?.fullName?.message}
                required
                sx={{ marginBottom: 2 }}
              />
            )}
          />
        </FormControl>
      </Grid>

      {/* Date of Birth */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined">
          <Controller
            name={`${prefix}.birthDay`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Date of Birth"
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors?.[prefix]?.birthDay}
                helperText={errors?.[prefix]?.birthDay?.message}
                required
                sx={{ marginBottom: 2 }}
              />
            )}
          />
        </FormControl>
      </Grid>

      {/* Place of Birth */}
      <Grid item xs={12} sm={6} md={4}>
        <FormControl fullWidth variant="outlined">
          <Controller
            name={`${prefix}.birthPlace`}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Place of Birth"
                type="text"
                variant="outlined"
                error={!!errors?.[prefix]?.birthPlace}
                helperText={errors?.[prefix]?.birthPlace?.message}
                required
                sx={{ marginBottom: 2 }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default PersonInfo;
