// src/services/apiService.js

import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // استخدام الاستيراد المسمي

const api = axios.create({
  baseURL: '/brqlink', // استخدام المسار النسبي
});

// إضافة التوكن إلى الترويسة إذا كان موجودًا
api.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token); // استخدام jwtDecode بشكل صحيح
      const currentTime = Date.now() / 1000;

      // إذا كان التوكن سينتهي خلال 5 دقائق، نقوم بطلب توكن جديد
      if (decodedToken.exp - currentTime < 300) {
        try {
          // استخدام api بدلاً من axios وضبط المسار النسبي
          const response = await api.post('/refresh-token', {}, {
            headers: { Authorization: `Bearer ${token}` },
          });
          token = response.data.token;
          localStorage.setItem('token', token);
        } catch (error) {
          console.error('Error refreshing token:', error);
          // إذا فشل تجديد التوكن، نقوم بتسجيل خروج المستخدم
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          window.location.href = '/';
          return Promise.reject(error);
        }
      }

      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// التعامل مع انتهاء صلاحية التوكن
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // إعادة توجيه المستخدم لتسجيل الدخول مرة أخرى
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      window.location.href = '/';
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
