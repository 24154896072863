// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function ProtectedRoute({ children }) {
  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');

  if (token && username) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // التوكن منتهي الصلاحية
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        return <Navigate to="/" />;
      } else {
        return children;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/" />;
  }
}

export default ProtectedRoute;
