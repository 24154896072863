// src/components/Navbar.js

import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

function Navbar() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Check for token

  const handleLogout = () => {
    // Remove tokens from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/'); // Redirect to login page
  };

  // Hide Navbar if not logged in
  if (!token) {
    return null; // Do not render Navbar
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#2E3B55' }}>
      <Toolbar>
        {/* Application Logo */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          BRQ Transfer
        </Typography>

        {/* Navigation Buttons with Icons */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            color="inherit"
            startIcon={<DashboardIcon />}
            onClick={() => navigate('/dashboard')}
          >
            Dashboard
          </Button>
          <Button
            color="inherit"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate('/create-transfer')}
          >
            Create Transfer
          </Button>
          <Button
            color="inherit"
            startIcon={<HistoryIcon />}
            onClick={() => navigate('/transfer-history')}
          >
            Transfer History
          </Button>
          <Button
            color="inherit"
            startIcon={<ExitToAppIcon />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
